import React from 'react';
import { Element } from 'slate';
import { DocumentImage } from 'app/slate/components/DocumentImage';

const isImage = (node) => Element.isElement(node) && node.type === 'image';

const image =
  (options = {}) =>
  (editor) => {
    const { renderElement, isVoid } = editor;
    editor.isVoid = (element) => isImage(element) || isVoid(element);
    editor.renderElement = (props) => {
      if (isImage(props.element)) {
        return <DocumentImage {...props} readOnly={!!options.readOnly} />;
      } else {
        return renderElement(props);
      }
    };

    return editor;
  };
export default image;
