import { useSlate } from 'slate-react';
import { Editor as SlateEditor } from 'slate';
import { Transforms } from 'slate';
import { Spinner } from 'app/components';
import { i18n } from 'app/utils/i18n';
import React from 'react';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

export const ImportTable = ({ onClose }) => {
  const references = useEditorSelector((editor) => editor.references);
  const editor = useSlate();

  const handleReferenceSelection = (reference) => {
    const content = reference.selectionContent;
    console.log(content);
    if (!content) {
      return;
    }

    const slateTable = {
      type: 'reference-table',
      referenceDataSuperId: reference.superId,
      children: [],
    };

    let point = SlateEditor.end(editor, editor.lastSelection);
    if (point) {
      const [pNode] = SlateEditor.first(editor, point);
      if (!!pNode.text) {
        Transforms.insertNodes(editor, slateTable, { voids: true, at: point });
      } else {
        Transforms.setNodes(editor, slateTable, { voids: true, at: point });
      }
    }
    onClose();
  };

  return (
    <div>
      <div className="header d-flex">
        <h3>{i18n('table-import.select-data')}</h3>
      </div>
      <div className="body">
        {!references ? (
          <Spinner />
        ) : (
          <table>
            <tbody>
              {references.length === 0 && <h1 className="text-center">{i18n('table-import.no-data')}</h1>}
              {references.map((data) => (
                <tr key={data.revisionId}>
                  <td>{data.name}</td>
                  <td className="align-right">
                    <button onClick={() => handleReferenceSelection(data)} className="btn btn-primary">
                      {i18n('table-import.select')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
