import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Editable, Slate } from 'slate-react';
import { Node, Editor as SlateEditor } from 'slate';
import root from 'react-shadow';
import { useTemplates } from './useTemplates';
import { useDocument } from 'app/api/documents/useDocument';
import useEditor from './useEditor';
import { EditorContext, MultiEditorContext, useEditorDispatch, MAIN_EDITOR_ID } from 'app/state/contexts/EditorContext';
import useSelection from './useSelection';
import editorCss from './editor-style';
import { setActiveSection, setActiveNode, setSection } from 'app/state/redux/documentSlice';
import useSave from './useSave';
import { useLocation, useHistory } from 'react-router';
import { useCurrentUser } from 'app/api/auth/user-queries';

const useSearchQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * This wrapper makes it possible to host multiple editors in the same window at the same time.
 * To do this you must provide a unique id to each editor.
 *
 * @param editorId Optional. Supply unique id if multiple editors are presented.
 */
export const Editor = (props) => {
  const { editorId, children, ...rest } = props;

  return (
    <MultiEditorContext.Provider value={editorId || MAIN_EDITOR_ID}>
      <SingleEditor {...rest}>{children}</SingleEditor>
    </MultiEditorContext.Provider>
  );
};

/**
 * The Document editor, it's parents (and their hooks), may NEVER depend on the redux state (eg. useSelector()).
 * If they do, it may cause in circular state dependence, and the application will be extremely slow when editing large documents.
 */
export const SingleEditor = (props) => {
  const {
    autoSave,
    children: toolbars,
    extensions,
    instance: { documentId, documentRevisionId, sectionId, sectionRevisionId, translationId },
    readOnly,
    sectionHeader,
    showDiff,
    withShadowDom = true,
    showErrors = true,
    usingStaticStructure,
    useDraft = false,
    withStyle,
    forcePaperFormat = false,
    useReferenceData = true,
    pageBorders = false,
  } = props;

  const lazyCss = useRef();
  const editorContainerRef = useRef(null);
  const [shadowHost, setShadowHost] = useState();
  const editor = useEditor(extensions, readOnly);
  const shouldUseDraft = useSearchQuery().get('useDraft') || useDraft;
  const embedd = useSearchQuery().get('embedd') || false;
  useSelection(editor, shadowHost);
  useTemplates(documentId);
  const { document, section, css, addTemplateToDocument } = useDocument(
    documentId,
    documentRevisionId,
    sectionId,
    sectionRevisionId,
    translationId,
    showErrors,
    shouldUseDraft,
    usingStaticStructure,
    withStyle,
    useReferenceData,
    embedd
  );
  if (css) {
    lazyCss.current = css;
  }
  const editorDispatch = useEditorDispatch();
  const saveSection = useSave(document || {}, section || {}, sectionRevisionId || null, translationId);
  const history = useHistory();
  const { user: currentUser } = useCurrentUser()?.data || {};

  editor.onInsertTemplate = addTemplateToDocument;

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      //Soft break on shift enter
      event.preventDefault();
      editor.insertText('\n');
    }
  };

  const handleChange = (newValue) => {
    if (editor.selection) {
      // So we know where to insert images and tables
      editor.lastSelection = editor.selection;
    }

    if (!!editor.selection && !!editor.selection.anchor) {
      const activeSection = editor.children[editor.selection.anchor.path[0]];
      const activeNode = SlateEditor.parent(editor, editor.selection.anchor.path)[0];
      editorDispatch(setActiveSection(activeSection));
      editorDispatch(setActiveNode(activeNode));
    }

    if (autoSave) {
      saveSection(newValue, editor);
    }
    editorDispatch(setSection({ ...section, content: newValue }));
  };

  useEffect(() => {
    if (shadowHost) {
      const yOffset = -117; // Offset for the sub toolbar
      const elementId = history.location.hash?.split('#')[1];
      const pageNode = findPageNode(editor, elementId);
      editorDispatch(setActiveSection(pageNode));

      const element = shadowHost.shadowRoot.querySelector(`#id-${elementId}`);
      const y = element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
      if (y) {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [history.location.hash, shadowHost, editor, editorDispatch]);

  if (!section?.content || section?.content.length === 0) {
    return null;
  }
  const sectionName = document?.content.sections.find((section) => section.superId === sectionId)?.name;

  return (
    <div ref={editorContainerRef} className="editor-container">
      <EditorContext.Provider value={{ editorContainerRef, shadowHost, documentRevisionId: document?.revisionId }}>
        <Slate editor={editor} value={section?.content || []} onChange={handleChange}>
          <style type="text/css">{lazyCss.current?.exposedCss}</style>
          <Shadowed withShadowDom={withShadowDom} className="editor-shadow-host" ref={setShadowHost}>
            <Editable
              readOnly={readOnly}
              renderElement={editor.renderElement}
              decorate={editor.decorate}
              onKeyDown={onKeyDown}
              renderLeaf={(...args) => editor.renderLeaf(...args)}
              className="editor"
              spellCheck={currentUser.userProperties?.spellCheck || false}
            />
            <style type="text/css">{lazyCss.current?.shadowedCss}</style>
            <style type="text/css">{editorCss}</style>
            {pageBorders ? <style type="text/css">{xhtmlCss}</style> : null}
            {!readOnly ? <style type="text/css">{decorateCss}</style> : null}
            {showDiff ? <style type="text/css">{diffCss}</style> : null}
            {withShadowDom ? (
              <>
                <link rel="stylesheet" href="/client/static/css/fontawesome.min.css" />
                <link rel="stylesheet" href="/client/static/css/regular.min.css" />
                <link rel="stylesheet" href="/client/static/css/solid.min.css" />
              </>
            ) : null}
            {forcePaperFormat && (
              <style type="text/css">
                {`
                  .page-content {
                    padding: 0 30px;
                    width: 630px !important;
                    height: 835px !important;
                  }
              `}
              </style>
            )}
          </Shadowed>
          {sectionHeader ? <div className="section-name fw-bold">{sectionName}</div> : null}
          {toolbars}
        </Slate>
      </EditorContext.Provider>
    </div>
  );
};

const decorateCss = `
    .layout-leaf-empty { 
    background: rgba(193, 210, 228, 0.23);
      outline: 1px dotted rgba(77, 184, 148, 0.5);
    }
    .layout-leaf:hover,
    .layout-leaf:focus-within {
      outline: 1px dotted rgba(77, 184, 148, 0.5);
    }
    [data-slate-void="true"].reference {
      outline: 2px solid rgba(77, 184, 148, 0.5);
    }
`;

const xhtmlCss = `
    .page-content {
      outline: 2px solid black;
    }
`;

const diffCss = `
  .diffed-deleted {
    background: #fbd8d8;
  }

  .diffed-added {
    background: #e0eff9;
  }

  .diffed-change-added {
    background: #e0eff9;
  }

  .diffed-change-deleted {
    background: #fbd8d8;
  }

  .diffed-change-selected {
    background: #ffd56e;
  }

  .page-wrapper.offset-page .page-content {
    background-color: lightgrey;
  }
`;

const Shadowed = forwardRef(function Shadowed(props, ref) {
  const { withShadowDom, children, ...restProps } = props;
  return withShadowDom ? (
    <root.div {...restProps} ref={ref}>
      {children}
    </root.div>
  ) : (
    <>{children}</>
  );
});

function findPageNode(editor, nodeId) {
  const nodeGenerator = Node.nodes({ children: editor.children });

  for (const [node, path] of nodeGenerator) {
    if (node.uuid === nodeId) {
      const page = editor.children[path[0]];
      return page ?? editor.children[0];
    }
  }
  return editor.children[0];
}
