import React from 'react';
import { createPortal } from 'react-dom';

const navbarElement = document.getElementById('navbar-root');

const NavbarPortal = ({ children, fullWidth = false }) => {
  // If element is missing then don't render the navbar
  if (!navbarElement) {
    return null;
  }

  return createPortal(
    <div className={`header-navbar grid--container ${fullWidth ? '' : 'columns'}`}>
      <div className={`navbar--container ${fullWidth ? '' : 'navbar--content-columns'}`}>{children}</div>
    </div>,
    navbarElement
  );
};

export default NavbarPortal;
